import get from 'lodash.get';
import has from 'lodash.has';

export const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.fdjservices.nirio';
export const IOS_URL = 'https://apps.apple.com/fr/app/nirio-services-de-paiement/id1629451356';

const getMobileOperatingSystem = (force?: boolean) => {
  const userAgent = navigator.userAgent || navigator.vendor || get(window, 'opera', '');

  if (/android/i.test(userAgent)) {
    window.location.replace(ANDROID_URL);
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !has(window, 'MSStream')) {
    window.location.replace(IOS_URL);
  }

  if (force) {
    window.location.replace(ANDROID_URL);
  }
};

export default getMobileOperatingSystem;
