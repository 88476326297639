import nirio from '../../assets/logo.png';
import nirioQrCode from '../../assets/qr_code_nirio.svg';
import vpQrCode from '../../assets/qr_code_vp.svg';
import lbcQrCode from '../../assets/qr_code_lbc.svg';
import spQrCode from '../../assets/qr_code_sp.svg';
import lbcProdQRCode from '../../assets/LeboncoinQRCode.svg';
import spProdQrCode from '../../assets/Showroomprive_QRCode.svg';
import vpProdQrCode from '../../assets/Veepee_QRCode.svg';
import appStore from '../../assets/app_store.svg';
import googlePlay from '../../assets/google_play.png';
import styled from 'styled-components';
import getMobileOperatingSystem, { ANDROID_URL, IOS_URL } from '../../utils';

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  @media screen and (max-width: 600px) {
    margin: 24px;
  }
`;

const TitleText = styled.span`
  color: #282d87;
  font-family: Arial Regular, sans-serif;
  font-family: Inter;
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`;

const SubTitleText = styled(TitleText)`
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 60px;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Image = styled.img`
  width: 15vw;
  @media screen and (max-width: 600px) {
    width: 40vw;
  }
`;

const ImageGoogle = styled.img`
  width: 18vw;
  margin-left: -15px;
  @media screen and (max-width: 600px) {
    width: 43vw;
  }
`;
const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

const LegalMention = styled.p`
  font-family: 'Inter';
  font-style: normal;
  color: rgba(130, 127, 127, 1);
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
`;

const QRCode = styled.img`
  max-width: 30%;
  @media screen and (max-width: 600px) {
    display: none;
  }
  @media screen and (max-width: 900px) {
    max-width: 200px;
  }
`;

export enum HomeType {
  default = 'DEFAULT',
  VP = 'VP',
  LBC = 'LBC',
  SP = 'SP',
  VP_PROD = 'VP_PROD',
  LBC_PROD = 'LBC_PROD',
  SP_PROD = 'SP_PROD'
}

interface HomeProps {
  redirect?: boolean;
  type?: HomeType;
}

function Home({ redirect, type = HomeType.default }: HomeProps) {
  if (redirect) {
    getMobileOperatingSystem();
  }

  let displayedQRCode: string;
  switch (type) {
    case HomeType.VP:
      displayedQRCode = vpQrCode;
      break;
    case HomeType.LBC:
      displayedQRCode = lbcQrCode;
      break;
    case HomeType.SP:
      displayedQRCode = spQrCode;
      break;
    case HomeType.LBC_PROD:
      displayedQRCode = lbcProdQRCode;
      break;
    case HomeType.SP_PROD:
      displayedQRCode = spProdQrCode;
      break;
    case HomeType.VP_PROD:
      displayedQRCode = vpProdQrCode;
      break;
    default:
      displayedQRCode = nirioQrCode;
  }

  return (
    <Root>
      <Header>
        <img style={{ maxHeight: 141, maxWidth: 334 }} src={nirio} alt="logo" />
      </Header>
      <BodyContainer>
        <DescriptionContainer>
          <TitleText>
            Pour télécharger l’App Nirio gratuitement
            <SubTitleText>, scannez le QR code</SubTitleText>
          </TitleText>

          <ImagesContainer>
            <a href={ANDROID_URL}>
              <ImageGoogle src={googlePlay} alt="Google play button" />
            </a>
            <a href={IOS_URL}>
              <Image src={appStore} alt="App Store button" />
            </a>
          </ImagesContainer>
        </DescriptionContainer>
        <QRCode src={displayedQRCode} alt="nirio-app" />
      </BodyContainer>
      <LegalMention>
        Apple et le logo Apple sont des marques d’Apple Inc., déposée aux États-Unis et dans
        d’autres pays. Google Play et le logo Google Play sont des marques de Google LLC.
      </LegalMention>
    </Root>
  );
}

export default Home;
