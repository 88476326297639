import { FC, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import getMobileOperatingSystem from '../../utils';

import nirio from '../../assets/logo.png';
import { Header } from '../Home';

const Root = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const QRCodeContainer = styled.div`
  height: auto;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  padding-bottom: 5px;
  border: 3px solid black;
  border-radius: 10px;
`;

const Button = styled.a`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  min-width: 64px;
  padding: 4px 10px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  height: 56px;
  border-radius: 56px;
  box-shadow: rgb(0 0 0 / 15%) 0px 4px 8px;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  background: rgb(34, 34, 179) !important;
  color: white !important;
`;

const TitleText = styled.div`
  color: #282d87;
  font-family: Arial Regular, sans-serif;
  font-family: Inter;
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const AddBill: FC = () => {
  const { datamatrix } = useParams();
  const size = 256;

  useEffect(getMobileOperatingSystem, []);

  return (
    <Root>
      <Header>
        <img style={{ maxHeight: 141, maxWidth: 334 }} src={nirio} alt="logo" />
      </Header>

      <TitleText>
        Pour préparer votre paiement Nirio, <br />
        scannez le QR code ci-dessous
      </TitleText>

      <div>
        <QRCodeContainer style={{ maxWidth: size }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={datamatrix}
            viewBox={`0 0 ${size} ${size}`}
          />
        </QRCodeContainer>
        <Button style={{ marginTop: 24 }} onClick={() => getMobileOperatingSystem(true)}>
          Installer Nirio
        </Button>
      </div>
    </Root>
  );
};

export default AddBill;
