import { FC } from 'react';

const List = {
  'Optimisation de la publicité': {
    Adjust: 'https://www.adjust.com/terms/privacy-policy/'
  },
  "Mesure d'audience et améliorations des contenus": {
    'Piano-Analytics':
      'https://support.piano.io/hc/fr/sections/4468207424658-Respect-de-la-vie-priv%C3%A9e'
  },
  'Personnalisation de la publicité': {
    Apple: 'https://searchads.apple.com/fr/privacy',
    Facebook: 'https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0',
    Google: 'https://policies.google.com/?hl=fr',
    'Le Bon Coin': 'https://www.leboncoin.fr/dc/cookies',
    Showroomprivé: 'https://m.showroomprive.com/moncompte/politique_confidentialite.aspx',
    Snapchat: 'https://values.snap.com/fr-FR/privacy/privacy-policy',
    'Tik Tok': 'https://www.tiktok.com/legal/page/eea/privacy-policy/fr',
    Veepee: 'https://www.veepee.fr/PrivacyPolicy/fr-FR/PP_Veepee_FR.pdf'
  }
};

const AppPartners: FC = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <h1>Liste des sociétés utilisant des cookies sur notre application</h1>
      {Object.entries(List).map(([title, partnerList]) => (
        <div key={title}>
          <h3>{title}:</h3>
          {Object.entries(partnerList).map(([partnerName, url]) => (
            <div key={partnerName}>
              <a rel="noreferrer" target="_blank" href={url}>
                Politique de Vie Privée {partnerName}
              </a>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AppPartners;
